import $ from 'jquery'

const Munki = (($) => {

//Smooth Scrolling For Internal Page Links
$(function() {
  $('a[href*=\\#spy]:not([href=\\#])').click(function() {
  if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
    if (target.length) {
    $('html,body').animate({
      scrollTop: target.offset().top - 60
    }, 1000);
    return false;
    }
  }
  });
});


function doowidth(){
    const winWidth = document.documentElement.clientWidth;


    if(winWidth < 760){

      $('a.colorbox img').unwrap();
    } else {

      $('a.colorbox').colorbox({maxHeight:"90%", maxWidth:"90%", rel:"colorbox"});
      $('a.colorbox-link').colorbox({maxHeight:"90%", maxWidth:"90%", rel:"colorbox-link"});

      $('a.colorbox').each(function(index){
        //console.log($(this).attr('mobile-url'));
        $(this).attr('href', $(this).attr('mobile-url'));
      });

    }


    var $cWidth = $('.carousel').width();
    var $cHeight = $('.carousel').height();

    var $lRatio = 0.3125;

    /*

    var $lWidth = ($cHeight / 100 * 40)  / $lRatio;

    var $lHeight = $lWidth * $lRatio;

    */

    var $lWidth = winWidth / 2;
    var $lHeight = $lWidth * $lRatio;

    console.log($lWidth);


    $('.slide-holder .logo a img').css("width",$lWidth);
    $('.slide-holder .logo a img').css("height",$lHeight);


    $('.slide-holder .logo').css("left",winWidth / 2 - $lWidth / 2);
    $('.slide-holder .logo').css("top",(winWidth * $lRatio) / 2 - $lHeight / 2);

  }

  doowidth();

  $('.slide-holder').css("visibility","visible");


  $('.carousel').carousel({
    interval: 4000
  })

  $(window).on('resize', function () {
    doowidth();
  });

  $("#menu-toggle a").click(function(e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
  });

  return Munki

})($)

export default Munki
